
const errorPageRouteName = 'Error' // 错误页面名称定义
const passGuardRouteList = [errorPageRouteName] // 不进入路由守卫的name

const appTitle = '华生智慧助手'

/** 定义支付方式 **/
const payWay = {
  WXPAY: { wayCode: 'WX_JSAPI', routeName: 'CashierWxpay' },
  ALIPAY: { wayCode: 'ALI_JSAPI', routeName: 'CashierAlipay' }
}

export default {
  errorPageRouteName: errorPageRouteName,
  successPageRouteName: 'Success',
  H5PayRouteName: 'H5PAY',
  BlankRouteName: 'Index',
  AppTitle: appTitle,
  passGuardRouteList: passGuardRouteList,
  urlTokenName: 'ppapiPay', // URL传递的token名称
  payWay: payWay,
  cacheToken: ''
}
