import Vue from 'vue'
import App from './App.vue'
import router from './router'
import wayCode from './utils/wayCode'
import config from './config'
import 'amfe-flexible'
Vue.config.productionTip = false
import NutUI from '@nutui/nutui'
import '@nutui/nutui/dist/nutui.css'
NutUI.install(Vue)

// import vConsole from './utils/vconsole'
import Vconsole from 'vconsole'
console.log('ENV:', process.env.NODE_ENV)
if (process.env.NODE_ENV === 'development') {
  const vConsole = new Vconsole()
  Vue.prototype.vConsole = vConsole
}

/**
 * 路由守卫
 *
 * @author terrfly
 * @date 2021/5/8 07:18
 */
router.beforeEach((to, from, next) => {
  // from: 当前导航正要离开的路由
  // to: 即将要进入的目标路由对象

  // 分发页面需要验证token+支付访问类型
  if (to.name === 'Hub') {
    // 获取不到参数
    const token = to.params[config.urlTokenName]
    // let token = 'test';  // 不提交
    if (token) { // 放置token信息
      config.cacheToken = token
    }

    const payCode = wayCode.getPayWay()
    console.log(payCode)
    if (!payCode) {
      next({ name: config.BlankRouteName, params: {}})
      return false
    }
  }

  // if (!config.cacheToken) {
  //   next({ name: config.errorPageRouteName, params: { errInfo: '请通过二维码进入支付页面！' }})
  //   return false
  // }

  next()
})

Vue.prototype.$config = config

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
